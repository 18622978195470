// src/components/Thumbnail.tsx
import React, { useState } from 'react';
import './Thumbnail.css'

interface ThumbnailProps {
  src: string;
  alt: string;
  className?: string; // Optional class name
}

const Thumbnail: React.FC<ThumbnailProps> = ({ src, alt, className }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => setIsOpen(!isOpen);

  return (
    <>
      <div className={`thumbnail ${className}`} onClick={toggleModal}>
        <img src={src} alt={alt} loading="lazy" />
      </div>
      {isOpen && (
        <div className="modal" onClick={toggleModal}>
          <img src={src} alt={alt} className="modal-image" />
        </div>
      )}
    </>
  );
};

export default Thumbnail;

// src/pages/Landing.tsx
import React from 'react';
import image from '../../images/pexels-bertellifotografia-799443.jpg';
import Hero from '../../components/hero/Hero';
import Thumbnail from '../../components/thumbnail/Thumbnail';

const images = [
    'https://picsum.photos/800/800?random=1',
    'https://picsum.photos/800/800?random=2',
    'https://picsum.photos/800/800?random=3',
    'https://picsum.photos/800/800?random=4',
    'https://picsum.photos/800/800?random=5',
    'https://picsum.photos/800/800?random=6',
    'https://picsum.photos/800/800?random=7',
    'https://picsum.photos/800/800?random=8',
    'https://picsum.photos/800/800?random=9',
    'https://picsum.photos/800/800?random=10',
    'https://picsum.photos/800/800?random=11',
    'https://picsum.photos/800/800?random=12',
    'https://picsum.photos/800/800?random=13',
    'https://picsum.photos/800/800?random=14',
    'https://picsum.photos/800/800?random=15',
    'https://picsum.photos/800/800?random=16',
    'https://picsum.photos/800/800?random=17',
    'https://picsum.photos/800/800?random=18',
    'https://picsum.photos/800/800?random=19',
    'https://picsum.photos/800/800?random=20',
    'https://picsum.photos/800/800?random=21',
    'https://picsum.photos/800/800?random=22',
    'https://picsum.photos/800/800?random=23',
    'https://picsum.photos/800/800?random=24',
    'https://picsum.photos/800/800?random=25',
    'https://picsum.photos/800/800?random=26',
    'https://picsum.photos/800/800?random=27',
    'https://picsum.photos/800/800?random=28',
    'https://picsum.photos/800/800?random=29',
    'https://picsum.photos/800/800?random=30',
    'https://picsum.photos/800/800?random=31',
'https://picsum.photos/800/800?random=32',
'https://picsum.photos/800/800?random=33',
'https://picsum.photos/800/800?random=34',
'https://picsum.photos/800/800?random=35',
'https://picsum.photos/800/800?random=36',
'https://picsum.photos/800/800?random=37',
'https://picsum.photos/800/800?random=38',
'https://picsum.photos/800/800?random=39',
'https://picsum.photos/800/800?random=40',
'https://picsum.photos/800/800?random=41',
'https://picsum.photos/800/800?random=42',
'https://picsum.photos/800/800?random=43',
'https://picsum.photos/800/800?random=44',
'https://picsum.photos/800/800?random=45',
'https://picsum.photos/800/800?random=46',
'https://picsum.photos/800/800?random=47',
'https://picsum.photos/800/800?random=48',
'https://picsum.photos/800/800?random=49',
'https://picsum.photos/800/800?random=50',
'https://picsum.photos/800/800?random=51',
'https://picsum.photos/800/800?random=52',
'https://picsum.photos/800/800?random=53',
'https://picsum.photos/800/800?random=54',
'https://picsum.photos/800/800?random=55',
'https://picsum.photos/800/800?random=56',
'https://picsum.photos/800/800?random=57',
'https://picsum.photos/800/800?random=58',
'https://picsum.photos/800/800?random=59',
'https://picsum.photos/800/800?random=60',

];

function getRandomSpanClass() {
    const spanOptions = [
        '',               // Default span
        'span-2',        // 1x2 span
        'span-3',        // 1x3 span
        'span-2-col',    // 2x1 span
        'span-4',        // 2x2 span
        'span-3-col',    // 3x1 span
    ];
    return spanOptions[Math.floor(Math.random() * spanOptions.length)];
}

function Landing() {
    return (
        <div className='landing'>
            <Hero
                image={image}
                headline="Gallery"
                subtext="Explore stunning images"
            />
            <h1>Johnni Riis Jensen</h1>
            <div className="image-gallery">
                {images.map((imgSrc, index) => (
                    <Thumbnail
                        key={index}
                        src={imgSrc}
                        alt={`Gallery Image ${index + 1}`}
                        className={getRandomSpanClass()} // Assign random class for dynamic span
                    />
                ))}
            </div>
        </div>
    );
}


export default Landing;
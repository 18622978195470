import React, { useState } from 'react';

const Header: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <header style={headerStyle}>
            {/* Spacer div to push the burger menu to the right */}
            <div style={{ flexGrow: 1 }}></div>
            <div style={burgerStyle} onClick={toggleMenu}>
                {isOpen ? (
                    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="5" y1="5" x2="25" y2="25" stroke="white" strokeWidth="4" />
                        <line x1="25" y1="5" x2="5" y2="25" stroke="white" strokeWidth="4" />
                    </svg>
                ) : (

                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 18L20 18" stroke="white" stroke-width="2" stroke-linecap="round" />
                        <path d="M4 12L20 12" stroke="white" stroke-width="2" stroke-linecap="round" />
                        <path d="M4 6L20 6" stroke="white" stroke-width="2" stroke-linecap="round" />
                    </svg>
                )}
            </div>
        </header>
    );
};

// Inline styles for simplicity
const headerStyle: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    height: '60px',
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    padding: '0 20px',
    zIndex: 1000,
};

const burgerStyle: React.CSSProperties = {
    cursor: 'pointer',
    width: '15%',
    height: '15%',
    position: 'relative',
    transition: 'transform 0.3s ease',
};

export default Header;

import React from 'react';
import './Hero.css';

interface HeroProps {
  image: string;
  headline: string;
  subtext: string;
}

const Hero: React.FC<HeroProps> = ({ image, headline, subtext }) => {
  return (
    <div className="hero-container" style={{ backgroundImage: `url(${image})` }}>
      <div className="overlay">
        <h1 className="hero-headline">{headline}</h1>
        <p className="hero-subtext">{subtext}</p>
      </div>
    </div>
  );
};

export default Hero;
